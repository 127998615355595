import React from 'react'
import logo from './wortlogo.png'
import grey from './grey2.png'
import discord from './discord.png'
import ReactDOM from 'react-dom/client'
import './index.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Button, Card, CardContent, CssBaseline, Stack } from '@mui/material'
import { Box, Grid } from '@mui/material'
import { Password } from './Password'
import { CookiesProvider } from 'react-cookie'

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        text: { primary: '#eee' },
        primary: {
            dark: '#AAA',
            main: '#CCC'
        },
        background: { paper: 'rgba(34,34,34,.7)' }
    }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <CookiesProvider>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />

                <Box
                    sx={{
                        display: 'flex',
                        width: '100vw',
                        height: '100vh',
                        alignItems: 'top',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        flexGrow: 1,
                        p: '2em'
                    }}>
                    <img
                        style={{
                            position: 'absolute',
                            width: '50%',
                            minWidth: '500px',
                            top: '-1em',
                            left: '-5em',
                            opacity: 0.07
                        }}
                        src={grey}
                        alt='logo'
                    />
                    <Grid
                        sx={{
                            maxWidth: '1000px',
                            width: '100%'
                        }}>
                        <Grid item xs={12}>
                            <img style={{ maxHeight: '60px', width: '100%', maxWidth: '350px' }} src={logo} alt='logo' />
                        </Grid>

                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <Card>
                                    <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                                        <Button
                                            variant='outlined'
                                            onClick={() => {
                                                window.open('INo Trident Mockup 032023.stl', '_blank')
                                            }}>
                                            INo Trident .stl File
                                        </Button>
                                        &nbsp;
                                        <Button
                                            variant='outlined'
                                            onClick={() => {
                                                window.open('INo Trident Mockup 032023.step', '_blank')
                                            }}>
                                            INo Trident .step File
                                        </Button>

                                        {/* 
                                        <Password />

                                        <Button
                                            onClick={() => {
                                                window.open('https://discord.gg/6yXwbpRART', '_blank')
                                            }}>
                                            <img style={{ width: '1.4em', height: '1em' }} src={discord} alt='discord' />
                                            &nbsp;Join Plasmics Discord
                                        </Button>
                                        <br />
                                        <Button
                                            onClick={() => {
                                                window.open('mailto:support@plasmics.com', '_blank')
                                            }}>
                                            ✉️ support@plasmics.com
                                        </Button>
                                        */}
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
        </CookiesProvider>
    </React.StrictMode>
)
